import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import SubLeftbarWrapper from '../../components/SubLeftbarWrapper/Loadable'
import PageWrapper from '../../components/PageWrapper/Loadable'
import ModalNotification from '../../components/ModalNotification/Loadable'
import CameraSubLeftBar from '../../components/CameraSubLeftBar/Loadable'
import AllViolationDetailPage from '../AllViolationDetailPage/Loadable'
import EachViolationDetailPage from '../EachViolationDetailPage/Loadable'
import DivideLine from '../../components/DivideLine/Loadable'
import moment from 'moment'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ViolationDetailStyled from './styledComponent'

import * as violationDetailActions from './actions'
import * as ApplicationActions from '../ApplicationLayout/actions'
import * as SelectSiteActions from '../SelectSitePage/actions'
import * as ProjectDetailActions from '../ProjectDetailPage/actions'

import { detectBrowser } from '../../utils'
import fetchErrorHandler from '../../utils/fetchErrorHandler'
import MESSAGE from './message'
import { FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_FAILURE, FRONTEND_ROUTES, CAMERA_ACTIVE, CAMERA_INACTIVE } from '../../utils'
import CollapseIcon from '../../asset/images/button/collapse.svg'
import ExpandIcon from '../../asset/images/button/expand.svg'
// eslint-disable-next-line no-unused-vars
let timing

export class ViolationDetailPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      lastSyncTime: moment(),
      isCompareMode: false,
      subLeftBarCollapse: false,
      failureModalData: {
        show: false,
        title: '',
        message: '',
        onPrimaryButtonClick: undefined,
        primaryButtonText: ''
      }
    }
  }

  async componentDidMount() {
    const projectID = this.props.match.params.projectID
    await this.props.setCurrentProjectCookie(projectID)
    this.loadListSite()
    this._loadCameraList()
  }

  loadListSite() {
    this.props.getListSite()
  }

  _loadCameraList() {
    const siteId = this.props.match.params.siteId
    this.props.loadCameraList(siteId)
  }

  componentDidUpdate(prevProps, prevState) {
    this.handleFetchLoadCameraList(prevProps)
    this.handleFetchSyncCameraList(prevProps)
    this.handleFetchLoadListSite(prevProps)
    this.handleFetchLoadAllViolation(prevProps)
    this.handleFetchLoadViolation(prevProps)
  }

  handleFetchLoadViolation(prevProps) {
    if (
      prevProps.eachViolationDetailPage.loadCameraData.syncStatus === FETCH_STATUS_REQUEST &&
      this.props.eachViolationDetailPage.loadCameraData.syncStatus === FETCH_STATUS_SUCCESS
    ) {
      this.resetSyncTime()
    }
  }

  handleFetchLoadAllViolation(prevProps) {
    if (
      prevProps.allViolationDetailPage.loadAllCameraDetail.syncStatus === FETCH_STATUS_REQUEST &&
      this.props.allViolationDetailPage.loadAllCameraDetail.syncStatus === FETCH_STATUS_SUCCESS
    ) {
      this.resetSyncTime()
    }
  }

  handleFetchLoadListSite(prevProps) {
    if (prevProps.loadSiteStatus.fetchStatus === FETCH_STATUS_REQUEST && this.props.loadSiteStatus.fetchStatus === FETCH_STATUS_FAILURE) {
      this.handleLoadListSiteFailure()
    }
  }

  handleFetchLoadCameraList(prevProps) {
    if (prevProps.loadCameraListStatus === FETCH_STATUS_REQUEST && this.props.loadCameraListStatus === FETCH_STATUS_FAILURE) {
      this.handleLoadCameraListFailure()
    }
  }

  handleFetchSyncCameraList(prevProps) {
    if (prevProps.syncCameraListStatus === FETCH_STATUS_REQUEST && this.props.syncCameraListStatus === FETCH_STATUS_FAILURE) {
      this.handleSyncCameraListFailure()
    }
  }

  handleSyncCameraListFailure() {
    const statusCode = this.props.loadCameraListError.status
    fetchErrorHandler(
      statusCode,
      {
        401: () => this.forceLogin()
      },
      this.showDefaultFailureModal
    )
  }

  handleLoadCameraListFailure() {
    const statusCode = this.props.loadCameraListError.status
    fetchErrorHandler(
      statusCode,
      {
        401: () => this.forceLogin()
      },
      this.showDefaultFailureModal
    )
  }

  showDefaultFailureModal = () => {
    this.setShowFailureModalData(
      this.getMessage('error_dialog_title_default'),
      this.getMessage('error_dialog_message_default'),
      this.onTryAgainClick,
      this.getMessage('try_again_button')
    )
  }

  forceLogin() {
    this.props.history.push(FRONTEND_ROUTES.loginRoute)
  }

  getDayRequest() {
    let day = this.props.date.format('YYYY-MM-DD')
    if (this.props.dateFilterType === 'week') {
      day = moment(this.props.date).startOf('week').format('YYYY-MM-DD')
    }
    return day
  }

  setShowFailureModalData = (title, message, onPrimaryButtonClick, primaryButtonText) => {
    this.setState({
      failureModalData: {
        show: true,
        title,
        message,
        onPrimaryButtonClick,
        primaryButtonText
      }
    })
  }
  resetSyncTime() {
    this.setState({
      lastSyncTime: moment()
    })
  }

  setHideFailureModalData = () => {
    this.setState({
      failureModalData: {
        show: false,
        title: '',
        message: '',
        onPrimaryButtonClick: undefined,
        primaryButtonText: ''
      }
    })
  }

  onTryAgainClick = () => {
    this.setHideFailureModalData()
    window.location.reload()
  }

  prepareSubmenuLeftBar(props) {
    if (props.cameraList) {
      this.prepareCameraListData()
    }
  }

  prepareCameraListData() {
    const { cameraList } = this.props
    const projectID = this.props.match.params.projectID
    const listMenu = cameraList.map((camData) => {
      return {
        path: `${FRONTEND_ROUTES.selectSiteRoute}/${projectID}/${this.props.match.params.siteId}/violation/${camData.camera_id}`,
        name: camData.name,
        status: camData.camera_status.toUpperCase(),
        id: camData.camera_id
      }
    })
    return listMenu
  }

  async onCameraClick(cameraId) {
    await this.props.setUseCurrentDateFilter(false)
    const projectID = this.props.match.params.projectID
    if (cameraId) {
      this.props.history.push(`${FRONTEND_ROUTES.selectSiteRoute}/${projectID}/${this.props.match.params.siteId}/violation/${cameraId}`)
    } else {
      this.props.history.push(`${FRONTEND_ROUTES.selectSiteRoute}/${projectID}/${this.props.match.params.siteId}/violation`)
    }
  }

  getMessage(property) {
    const msg = MESSAGE[this.props.currentUser?.language]
    return msg[property]
  }

  collapseSubLeftBar() {
    this.setState({
      subLeftBarCollapse: true
    })
  }

  expandSubLeftBar() {
    this.setState({
      subLeftBarCollapse: false
    })
  }

  getSubLeftbarTitle() {
    let titleClassName = detectBrowser() === 'Firefox' ? ' is-firefox' : ''
    return <div className={'left-bar-header text-style' + titleClassName}>{this.getMessage('violation_subleftbar')}</div>
  }

  renderCollapseButton() {
    return (
      <div className="collapse-button" onClick={() => this.collapseSubLeftBar()}>
        <img src={CollapseIcon} alt="" />
      </div>
    )
  }

  renderExpandButton() {
    return (
      <div className="expand-button" onClick={() => this.expandSubLeftBar()}>
        <img src={ExpandIcon} alt="" />
      </div>
    )
  }

  getOnlineCamera() {
    return this.props.cameraList.filter(
      (camera) => camera.camera_status.toUpperCase() === CAMERA_ACTIVE || camera.camera_status.toUpperCase() === CAMERA_INACTIVE
    ).length
  }

  getSubLeftbarSubTitle() {
    return (
      <div className="left-bar-sub-header text-style">{`${this.getMessage('camera_subleftbar')} ${this.getOnlineCamera()}/${
        this.props.cameraList.length
      } ${this.getMessage('active_subleftbar')}`}</div>
    )
  }

  getContentSubLeftbar() {
    let listMenu = (
      <CameraSubLeftBar
        cameraName={this.getMessage('all_camera_subleftbar')}
        cameraStatus={''}
        isClicked={this.props.match.url === this.props.location.pathname}
        isLoading={false}
        onClick={() => this.onCameraClick()}
      />
    )
    let listCamera = <CameraSubLeftBar cameraName={''} cameraStatus={''} isClicked={false} isLoading={true} onClick={() => null} />

    const preparedCameraList = this.prepareCameraListData()
    if (preparedCameraList.length > 0) {
      listCamera = preparedCameraList.map((camera) => {
        const isActive = camera.path === this.props.location.pathname
        return (
          <CameraSubLeftBar
            key={camera.name + camera.id}
            cameraName={camera.name}
            cameraStatus={camera.status}
            isClicked={isActive}
            isLoading={false}
            onClick={() => this.onCameraClick(camera.id)}
          />
        )
      })
      if (this.props.loadCameraListStatus === FETCH_STATUS_REQUEST) {
        listCamera = <CameraSubLeftBar cameraName={''} cameraStatus={''} isClicked={false} isLoading={true} onClick={() => null} />
      }
    }
    return (
      <div className="camera-list-container">
        {listMenu}
        {listCamera}
      </div>
    )
  }

  shouldShowSyncText() {
    const { syncCameraListStatus } = this.props
    const { loadAllCameraDetail } = this.props.allViolationDetailPage
    const { loadCameraData } = this.props.eachViolationDetailPage
    return (
      syncCameraListStatus === FETCH_STATUS_REQUEST ||
      loadAllCameraDetail.syncStatus === FETCH_STATUS_REQUEST ||
      loadCameraData.syncStatus === FETCH_STATUS_REQUEST
    )
  }

  getSyncText() {
    let message = `${this.getMessage('last_sync_subleftbar')} ${this.state.lastSyncTime.format('HH:mm:ss')}`
    if (this.shouldShowSyncText()) {
      message = `${this.getMessage('now_sync_subleftbar')}...`
    }
    return message
  }

  getSubLeftbarFooter() {
    const latestYear = new Date().getFullYear()
    return (
      <div className="left-bar-footer">
        <div className="message text-style">{this.getMessage('sync_info_subleftbar')}</div>
        <div className="sync-container text-style">{this.getSyncText()}</div>
        <div className="footer-zone">
          <DivideLine className="footer-line" />
          <div className="footer-msg text-style">
            © {latestYear} Sertis Co., Ltd. <br />
            {this.getMessage('subleftbar_footer')}
          </div>
        </div>
      </div>
    )
  }

  getSubLeftbar() {
    if (this.state.subLeftBarCollapse) {
      return <SubLeftbarWrapper className="collapse">{this.renderExpandButton()}</SubLeftbarWrapper>
    } else {
      return (
        <SubLeftbarWrapper className="dashboard-left-bar">
          {this.getSubLeftbarTitle()}
          {this.renderCollapseButton()}
          {this.getSubLeftbarSubTitle()}
          <DivideLine className="leftbar-line" />
          {this.getContentSubLeftbar()}
          <DivideLine className="leftbar-line" />
          {this.getSubLeftbarFooter()}
        </SubLeftbarWrapper>
      )
    }
  }

  getFailureModal() {
    let output = null
    if (this.state.failureModalData.show) {
      output = (
        <ModalNotification
          className={'default-failure-modal'}
          title={this.state.failureModalData.title}
          message={this.state.failureModalData.message}
          onPrimaryButtonClick={this.state.failureModalData.onPrimaryButtonClick}
          primaryButtonText={this.state.failureModalData.primaryButtonText}
        />
      )
    }
    return output
  }

  render() {
    return (
      <ViolationDetailStyled>
        <PageWrapper subLeftbar={this.getSubLeftbar()} subleftBarCollapse={this.state.subLeftBarCollapse}>
          <Switch>
            <Route exact path={`${FRONTEND_ROUTES.selectSiteRoute}/:projectID/:siteId/violation`} component={AllViolationDetailPage} />
            <Route path={`${FRONTEND_ROUTES.selectSiteRoute}/:projectID/:siteId/violation/:id`} component={EachViolationDetailPage} />
          </Switch>
        </PageWrapper>
        {this.getFailureModal()}
      </ViolationDetailStyled>
    )
  }
}

/* istanbul ignore next */
const mapStateToProps = (state) => {
  return {
    cameraList: state.violationDetailPage.cameraList,
    allViolationDetailPage: state.allViolationDetailPage,
    eachViolationDetailPage: state.eachViolationDetailPage,
    loadCameraListStatus: state.violationDetailPage.loadCameraListStatus.fetchStatus,
    loadCameraListError: state.violationDetailPage.loadCameraListStatus.error,
    syncCameraListStatus: state.violationDetailPage.loadCameraListStatus.syncStatus,
    syncCameraListError: state.violationDetailPage.loadCameraListStatus.error,
    loadSiteStatus: state.selectSitePage.loadSiteStatus,
    currentUser: state.loginPage.loginAPI.userData,
    date: state.applicationPage.date,
    dateFilterType: state.applicationPage.dateFilterType
  }
}

/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(Object.assign({}, violationDetailActions, SelectSiteActions, ProjectDetailActions, ApplicationActions), dispatch)
}

ViolationDetailPage.propTypes = {
  cameraList: PropTypes.array,
  loadCameraListStatus: PropTypes.string,
  loadCameraList: PropTypes.func,
  syncCameraList: PropTypes.func,
  syncCameraListStatus: PropTypes.string,
  currentUser: PropTypes.shape({
    email: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    language: PropTypes.string
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(ViolationDetailPage)
